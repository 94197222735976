import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Dialog, Checkbox, Button } from "components"
import { AuthorsContent } from "components/content"
import { Language, LOCAL_STORAGE, LANGUAGES, LANGUAGE_LABELS, ALL_SONGS } from "utils/constants"

import styles from "./SettingsDialog.module.css"

interface SettingsDialogProps {
    onClose: () => unknown
    onManualDialogOpen: () => unknown
    onChangelogDialogOpen: () => unknown
    onCopyrightsDialogOpen: () => unknown
    onPrivacyPolicyDialogOpen: () => unknown
    onSupportDialogOpen: () => unknown
    setAreTransitionEffectsActive: () => unknown
    toggleDownloadPermission: () => unknown
    areTransitionEffectsActive: boolean
    hasDownloadPermission: boolean
    songsDownloaded: number
}

function SettingsDialog({
    areTransitionEffectsActive,
    hasDownloadPermission,
    songsDownloaded,
    onClose,
    onManualDialogOpen,
    onChangelogDialogOpen,
    onCopyrightsDialogOpen,
    onPrivacyPolicyDialogOpen,
    onSupportDialogOpen,
    setAreTransitionEffectsActive,
    toggleDownloadPermission
}: SettingsDialogProps) {
    const { t, i18n } = useTranslation()
    const [language, setLanguage] = useState<Language>(window.localStorage.getItem(LOCAL_STORAGE.language) as Language || LANGUAGES[0])
    const [isMusicDownloading, setIsMusicDownloading] = useState<boolean>(hasDownloadPermission && !songsDownloaded)

    useEffect(() => {
        setIsMusicDownloading(hasDownloadPermission && !songsDownloaded)
    }, [ hasDownloadPermission, songsDownloaded ])

    useEffect(() => {
        i18n.changeLanguage(language)
        window.localStorage.setItem(LOCAL_STORAGE.language, language)
    }, [i18n, language])

    const downloadStateLabel = isMusicDownloading ? "downloading" : songsDownloaded ? "on" : "off"

    return (
        <Dialog
            isShown
            title={t("dialog.settings.title")}
            onExit={onClose}
            onAccept={onClose}
        >
            <section className={styles.root}>
                <h3>{t("dialog.settings.playback.title")}</h3>
                <Checkbox
                    id="transition"
                    label={t("dialog.settings.playback.transition")}
                    checked={areTransitionEffectsActive}
                    onChange={setAreTransitionEffectsActive}
                />
                <hr />
                <h3>{t("dialog.settings.language")}</h3>
                <section className={styles.language}>
                    {LANGUAGES.map(currentLanguage => (
                        <Checkbox
                            key={currentLanguage}
                            checked={currentLanguage === language}
                            onChange={() => setLanguage(currentLanguage)}
                            id={currentLanguage.toString()}
                            label={LANGUAGE_LABELS[currentLanguage]}
                        />
                    ))}
                </section>
                <hr />
                <section className={styles.download}>
                    <h3>{t("dialog.settings.download.title")}</h3>
                    <p>{t("dialog.settings.download.description")}</p>
                    <p>{t("dialog.settings.download.state", { current: songsDownloaded, all: ALL_SONGS.length })}</p>
                    <Checkbox
                        id="downloadPermission"
                        label={t(`dialog.settings.download.checkbox.${downloadStateLabel}`)}
                        checked={!hasDownloadPermission}
                        onChange={toggleDownloadPermission}
                    />
                </section>
                <hr />
                <section className={styles.withLink}>
                    <h3>{t("dialog.settings.manual")}</h3>
                    <Button title={t("dialog.settings.more")} type="QUESTION" onClick={onManualDialogOpen} />
                </section>
                <hr />
                <section className={styles.withLink}>
                    <h3>{t("dialog.settings.support")}</h3>
                    <Button title={t("dialog.settings.more")} type="QUESTION" onClick={onSupportDialogOpen} />
                </section>
                <AuthorsContent />
                <hr />
                <section className={styles.withLink}>
                    <h3>{t("dialog.settings.changelog")}</h3>
                    <Button title={t("dialog.settings.more")} type="QUESTION" onClick={onChangelogDialogOpen} />
                </section>
                <section className={styles.withLink}>
                    <h3>{t("dialog.settings.copyrights")}</h3>
                    <Button title={t("dialog.settings.more")} type="QUESTION" onClick={onCopyrightsDialogOpen} />
                </section>
                <section className={styles.withLink}>
                    <h3>{t("dialog.settings.privacy-policy")}</h3>
                    <Button title={t("dialog.settings.more")} type="QUESTION" onClick={onPrivacyPolicyDialogOpen} />
                </section>
            </section>
        </Dialog>
    )
}

export default SettingsDialog