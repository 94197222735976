import { useTranslation } from "react-i18next"
import { Language, languageCodeToExternalSupportWebsiteLanguageCode } from "utils/constants";
import styles from "./SupportContent.module.css"

function SupportContent() {
    const { t, i18n } = useTranslation()
    const currentLanguage = languageCodeToExternalSupportWebsiteLanguageCode(i18n.language as Language);
    const externalSupportWebsiteLanguageAddressSufix = currentLanguage.length > 0 ? `?l=${currentLanguage}` : '';

    return (
        <section className={styles.root}>
            <p>
                {t("content.support.content-1")}
                <a href={`https://buymeacoffee.com/piotrekzpolski${externalSupportWebsiteLanguageAddressSufix}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${process.env.PUBLIC_URL}/assets/bag.png`} alt={t("content.support.alt")} />
                    <span>{t("content.support.link")}</span>
                </a>
            </p>
        </section>
    )
}

export default SupportContent